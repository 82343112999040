var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comments-module" },
    [
      _vm.error
        ? _c("v-alert", { attrs: { prominent: "", type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("comments loading error")) + " ")
          ])
        : _vm._e(),
      _vm.loading && !_vm.error
        ? _c("v-text-field", {
            attrs: {
              color: "success",
              loading: "",
              disabled: "",
              "hide-details": ""
            }
          })
        : _vm._e(),
      !_vm.loading && !_vm.error
        ? _c(
            "div",
            { staticClass: "comments" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "comments__top",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "comments__input" },
                    [
                      !_vm.$vuetify.breakpoint.xs
                        ? _c("v-text-field", {
                            staticClass: "text-field",
                            attrs: {
                              solo: "",
                              "hide-details": "",
                              rules: [_vm.requiredRule],
                              label: _vm._f("upperFirst")(
                                _vm.$t("enter your comment text")
                              )
                            },
                            model: {
                              value: _vm.text,
                              callback: function($$v) {
                                _vm.text = $$v
                              },
                              expression: "text"
                            }
                          })
                        : _vm._e(),
                      _vm.$vuetify.breakpoint.xs
                        ? _c("v-text-field", {
                            staticClass: "text-field",
                            attrs: {
                              solo: "",
                              "hide-details": "",
                              label: _vm._f("upperFirst")(
                                _vm.$t("enter your comment text")
                              ),
                              "append-icon": "mdi-arrow-right"
                            },
                            on: { "click:append": _vm.onSubmit },
                            model: {
                              value: _vm.text,
                              callback: function($$v) {
                                _vm.text = $$v
                              },
                              expression: "text"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "comments__button" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-capitalize button button_purple",
                          staticStyle: { "letter-spacing": "0" },
                          attrs: {
                            color: "primary",
                            height: 60,
                            "x-large": "",
                            width: "100%",
                            type: "submit"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("upperFirst")(_vm.$t("congratulate"))
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "count comments__count" },
                    [
                      _c("c-img", {
                        attrs: {
                          src: "assets/img/" + _vm.themePath + "/star.svg",
                          alt: ""
                        }
                      }),
                      _c("div", { staticClass: "count__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.meta.totalCount) +
                            " " +
                            _vm._s(_vm.$t("congratulated")) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.$vuetify.breakpoint.xs
                    ? _c("div", { staticClass: "comments__mobile-count" }, [
                        _c(
                          "div",
                          { staticClass: "star-counter" },
                          [
                            _c("c-img", {
                              staticClass: "star-counter__image",
                              attrs: {
                                src: "assets/img/theme/star-2.svg",
                                alt: ""
                              }
                            }),
                            _c("div", { staticClass: "star-counter__count" }, [
                              _vm._v(" " + _vm._s(_vm.meta.totalCount) + " ")
                            ])
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("div", { staticClass: "comments__list" }, [
                _c(
                  "div",
                  { staticClass: "comments-list" },
                  _vm._l(_vm.firstLevelComments, function(
                    comment,
                    commentIndex
                  ) {
                    return _c(
                      "div",
                      { key: comment.id, staticClass: "comments-list__item" },
                      [
                        _c("div", { staticClass: "comment" }, [
                          _c(
                            "div",
                            { staticClass: "comment__main" },
                            [
                              _c("Avatar", {
                                staticClass: "comment__avatar",
                                attrs: {
                                  small: "",
                                  src:
                                    (comment.author.avatar &&
                                      _vm.getLinkByFileId(
                                        comment.author.avatar
                                      )) ||
                                    _vm.avatarPlug,
                                  alt: "",
                                  size: "44"
                                }
                              }),
                              _c("div", { staticClass: "comment__content" }, [
                                _c("div", { staticClass: "comment__top" }, [
                                  _vm._v(
                                    " " + _vm._s(comment.author.fullName) + " "
                                  )
                                ]),
                                _c("div", { staticClass: "comment__text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        comment.isTranslated
                                          ? comment.translated || comment.text
                                          : comment.text
                                      ) +
                                      " "
                                  )
                                ]),
                                _vm.activeCommentIndex !== commentIndex &&
                                !comment.answer
                                  ? _c(
                                      "div",
                                      { staticClass: "comment__reply" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "reply",
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleComment(
                                                  commentIndex
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "reply__icon" },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(" mdi-share ")
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "reply__text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("reply")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple",
                                                value: false,
                                                expression: "false"
                                              }
                                            ],
                                            staticClass: "translate",
                                            attrs: {
                                              loading:
                                                _vm.translateLoading &&
                                                commentIndex ===
                                                  _vm.translateLoadingIndex,
                                              text: "",
                                              plain: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.translateLoadingIndex = commentIndex
                                                _vm.translateComment(
                                                  commentIndex
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "translate__icon"
                                              },
                                              [
                                                _c("c-img", {
                                                  attrs: {
                                                    src:
                                                      "assets/img/svg/translate.svg",
                                                    alt: ""
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "translate__text",
                                                style: comment.isTranslated
                                                  ? { color: "#4C61CF" }
                                                  : ""
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("upperFirst")(
                                                        _vm.$t("translate")
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          ),
                          _vm.getAnswersByCommentId(comment.id).length
                            ? _c(
                                "div",
                                { staticClass: "comment__answers" },
                                _vm._l(
                                  _vm.getAnswersByCommentId(comment.id),
                                  function(answer) {
                                    return _c(
                                      "div",
                                      {
                                        key: answer.id,
                                        staticClass: "answer comment__answer"
                                      },
                                      [
                                        _c("Avatar", {
                                          staticClass: "answer__avatar",
                                          attrs: {
                                            small: "",
                                            src: _vm.getLinkByFileId(
                                              answer.author.avatar
                                            ),
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "answer__text" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(answer.text) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.activeCommentIndex === commentIndex
                            ? _c(
                                "div",
                                { staticClass: "comment__form" },
                                [
                                  _c("AnswerForm", {
                                    on: {
                                      submit: function(value, cb) {
                                        return _vm.onSubmitAnswer(
                                          {
                                            text: value,
                                            repliedId: comment.id
                                          },
                                          cb
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }