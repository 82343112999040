import Vuex from 'vuex'
import * as api from '@/services/api/comments'

const defaultState = () => ({
  meta: {
    totalCount: 0
  },

  items: []
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    deserializeFetchResponse: (state, getters) => data => ({
      comments: data.comments.map(el => getters.deserializeComment(el)),
      meta: {
        totalCount: data.number_of_greetings || 0
      }
    }),

    deserializeComment: (state, getters) => data => ({
      id: data.id,
      text: data.text,
      repliedId: data.replied?.id,
      author: getters.deserializeAuthor(data.user)
    }),

    deserializeAuthor: () => data => ({
      avatar: data.avatar,
      fullName: data.full_name,
      id: data.id
    }),

    serializeComment: () => data => ({
      text: data.text,
      // eslint-disable-next-line @typescript-eslint/camelcase
      replied_id: data.repliedId ?? null
    })
  },

  actions: {
    async fetchComments ({ commit, getters }, { entity, slug }) {
      const { data } = await api.getComments({ entity, slug })
      const { comments, meta } = getters.deserializeFetchResponse(data)

      commit('setComments', comments)
      commit('setMeta', meta)
    },

    async sendMessage ({ commit, getters }, { entity, slug, comment }) {
      const { data } = await api.sendMessage({
        entity,
        slug,
        ...getters.serializeComment(comment)
      })
      commit('addComment', getters.deserializeComment(data))
    }
  },

  mutations: {
    setComments (state, payload) {
      state.items = payload
    },

    setMeta (state, payload) {
      state.meta = payload
    },

    addComment (state, payload) {
      state.items.push(payload)
      state.meta = {
        ...state.meta,
        totalCount: state.meta.totalCount + 1
      }
    }
  }
})
