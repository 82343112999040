import Vuex from 'vuex'
import { getNomineeBySlug } from '@/services/api/translatesAndContent'

export const defaultState = () => ({
  user: {
    name: null,
    photo: null,
    status: null,
    country: null,
    city: null,
    superiorMaster: null,
    slug: null
  }
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    deserializeNominee: () => data => ({
      name: data.name,
      photo: data.photo,
      status: data.status,
      country: data.country,
      city: data.city,
      superiorMaster: data.superior_master,
      slug: data.slug
    })
  },

  actions: {
    async fetchUser ({ commit, getters }, slug) {
      const { data } = await getNomineeBySlug(slug)
      if (!data) throw new Error(404)

      commit('updateUser', getters.deserializeNominee(data))
    }
  },

  mutations: {
    updateUser (state, user) {
      state.user = user
    }
  }
})
