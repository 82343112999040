import apiClient from './apiClient'
import userService from '@/services/user'
import { MEMBER_PREFIX } from '@/constants/apiPrefixes'

export const ENTITY_NOMINEE = 'nominees'
export const ENTITY_SPEAKER = 'speakers'

export const getComments = ({ entity, slug }) => apiClient.get(`${MEMBER_PREFIX}/${entity}/${slug}`)

export const sendMessage = async ({ entity, slug, ...comment }) =>
  apiClient.post(`${MEMBER_PREFIX}/${entity}/${slug}/comments`, {
    ...comment,
    user_id: await userService.getUser().then(res => res.id)
  })
