var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-nominee" },
    [
      _c("Hero", [_c("div", { staticClass: "hero-inner" })]),
      _c("v-container", [
        _c("div", { staticClass: "profile-nominee__inner" }, [
          _c("div", { staticClass: "nominee profile-nominee__block" }, [
            !_vm.isLoading
              ? _c("div", { staticClass: "nominee__inner" }, [
                  _c("div", { staticClass: "nominee__left" }, [
                    _c(
                      "div",
                      { staticClass: "nominee__avatar" },
                      [
                        _c("Avatar", {
                          staticClass: "nominee__avatar-image",
                          attrs: {
                            src:
                              _vm.user.photo &&
                              _vm.getLinkByFileName("ranks", _vm.user.photo),
                            small: _vm.isForum22
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "nominee__right" },
                    [
                      _c(
                        "div",
                        { staticClass: "nominee-status nominee__status" },
                        [
                          _c(
                            "div",
                            { staticClass: "nominee-status__left" },
                            [
                              _c("c-img", {
                                attrs: {
                                  src:
                                    "assets/img/" +
                                    _vm.themePath +
                                    "/crown-full.svg",
                                  alt: ""
                                }
                              }),
                              _vm.isForum22
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "nominee-status__left-text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t(_vm.crownText)) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.user && _vm.user.status
                            ? _c(
                                "div",
                                { staticClass: "nominee-status__right" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("upperFirst")(
                                          _vm.$tc(
                                            _vm.user.status.toLowerCase(),
                                            1
                                          )
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c("div", { staticClass: "nominee__name" }, [
                        _vm._v(" " + _vm._s(_vm.user.name) + " ")
                      ]),
                      _c("div", { staticClass: "nominee__location" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.country) +
                            " " +
                            _vm._s(_vm.user.country && _vm.user.city && ", ") +
                            " " +
                            _vm._s(_vm.user.city) +
                            " "
                        )
                      ]),
                      _vm.user.superiorMaster
                        ? [
                            _c(
                              "div",
                              { staticClass: "nominee__master-title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("superior master")) + " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "nominee-master" }, [
                              _c(
                                "div",
                                { staticClass: "nominee-master__right" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.user.superiorMaster) + " "
                                  )
                                ]
                              )
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _vm.user.slug
            ? _c(
                "div",
                { staticClass: "profile-nominee__comments" },
                [
                  _c("Comments", {
                    attrs: { entity: _vm.ENTITY_NOMINEE, slug: _vm.user.slug }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "v-overlay",
        { attrs: { value: _vm.isLoading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }