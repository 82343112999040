var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "answer-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "answer-form__input" },
        [
          _c("v-text-field", {
            attrs: { label: "", "single-line": "", rules: [_vm.requiredRule] },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "answer-form__submit" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-capitalize",
              attrs: { text: "", color: "primary", type: "submit" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }