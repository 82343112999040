<template>
  <v-form
    ref="form"
    class="answer-form"
    @submit.prevent="onSubmit"
  >
    <div class="answer-form__input">
      <v-text-field
        v-model="text"
        label=""
        single-line
        :rules="[requiredRule]"
      />
    </div>
    <div class="answer-form__submit">
      <v-btn
        class="text-capitalize"
        text
        color="primary"
        type="submit"
      >
        {{ $t('send') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { requiredRule } from '@/utils/validate'

export default {
  name: 'AnswerForm',

  data () {
    return {
      text: ''
    }
  },

  methods: {
    requiredRule,

    onSubmit () {
      if (!this.$refs.form.validate()) return
      this.$emit('submit', this.text, () => {
        this.$refs.form.reset()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.answer-form {
  &__input {
    flex: 1;
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
