<template>
  <div class="comments-module">
    <v-alert
      v-if="error"
      prominent
      type="error"
    >
      {{ $t('comments loading error') }}
    </v-alert>

    <v-text-field
      v-if="loading && !error"
      color="success"
      loading
      disabled
      hide-details
    />

    <div v-if="!loading && !error" class="comments">
      <v-form
        ref="form"
        class="comments__top"
        lazy-validation
        @submit.prevent="onSubmit"
      >
        <div class="comments__input">
          <v-text-field
            v-if="!$vuetify.breakpoint.xs"
            v-model="text"
            class="text-field"
            solo
            hide-details
            :rules="[requiredRule]"
            :label="$t('enter your comment text') | upperFirst"
          />

          <v-text-field
            v-if="$vuetify.breakpoint.xs"
            v-model="text"
            class="text-field"
            solo
            hide-details
            :label="$t('enter your comment text') | upperFirst"
            append-icon="mdi-arrow-right"
            @click:append="onSubmit"
          />
        </div>

        <div class="comments__button">
          <v-btn
            color="primary"
            :height="60"
            x-large
            style="letter-spacing: 0;"
            class="text-capitalize button button_purple"
            width="100%"
            type="submit"
          >
            {{ $t('congratulate') | upperFirst }}
          </v-btn>
        </div>

        <div class="count comments__count">
          <c-img
            :src="`assets/img/${themePath}/star.svg`"
            alt=""
          />

          <div class="count__text">
            {{ meta.totalCount }} {{ $t('congratulated') }}
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.xs" class="comments__mobile-count">
          <div class="star-counter">
            <c-img
              class="star-counter__image"
              src="assets/img/theme/star-2.svg"
              alt=""
            />

            <div class="star-counter__count">
              {{ meta.totalCount }}
            </div>
          </div>
        </div>
      </v-form>

      <div class="comments__list">
        <div class="comments-list">
          <div
            v-for="(comment, commentIndex) in firstLevelComments"
            :key="comment.id"
            class="comments-list__item"
          >
            <div class="comment">
              <div class="comment__main">
                <Avatar
                  class="comment__avatar"
                  small
                  :src="comment.author.avatar && getLinkByFileId(comment.author.avatar) || avatarPlug"
                  alt=""
                  size="44"
                />

                <div class="comment__content">
                  <div class="comment__top">
                    {{ comment.author.fullName }}
                  </div>

                  <div class="comment__text">
                    {{ comment.isTranslated ? comment.translated || comment.text : comment.text }}
                  </div>

                  <div v-if="activeCommentIndex !== commentIndex && !comment.answer" class="comment__reply">
                    <div class="reply" @click="toggleComment(commentIndex)">
                      <div class="reply__icon">
                        <v-icon>
                          mdi-share
                        </v-icon>
                      </div>

                      <div class="reply__text">
                        {{ $t('reply') }}
                      </div>
                    </div>
                    <v-btn
                      v-ripple="false"
                      :loading="translateLoading && commentIndex === translateLoadingIndex"
                      text
                      plain
                      class="translate"
                      @click="translateLoadingIndex = commentIndex; translateComment(commentIndex)"
                    >
                      <div class="translate__icon">
                        <c-img src="assets/img/svg/translate.svg" alt="" />
                      </div>
                      <div :style="comment.isTranslated ? {color: '#4C61CF'} : ''" class="translate__text">
                        {{ $t('translate') | upperFirst }}
                      </div>
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="getAnswersByCommentId(comment.id).length" class="comment__answers">
                <div
                  v-for="answer in getAnswersByCommentId(comment.id)"
                  :key="answer.id"
                  class="answer comment__answer"
                >
                  <Avatar
                    class="answer__avatar"
                    small
                    :src="getLinkByFileId(answer.author.avatar)"
                    alt=""
                  />

                  <div class="answer__text">
                    {{ answer.text }}
                  </div>
                </div>
              </div>

              <div
                v-if="activeCommentIndex === commentIndex"
                class="comment__form"
              >
                <AnswerForm
                  @submit="(value, cb) => onSubmitAnswer({text: value, repliedId: comment.id}, cb)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from './store'
import { mapActions, mapState } from 'vuex'
import { requiredRule } from '@/utils/validate'
import AnswerForm from './components/AnswerForm'
import { notifySuccess, notifyError } from '@/utils/notify'
import { getLinkByFileId } from '@/utils/mediaHelpers'
import translate from '@/utils/translate'
import Avatar from '@/components/Avatar'
import { cloneDeep } from 'lodash'
import { getThemePath } from '@/utils/common'

export default {
  name: 'Comments',

  store,

  components: {
    AnswerForm,
    Avatar
  },

  props: {
    entity: {
      type: String,
      default: null,
      required: true
    },

    slug: {
      type: String,
      default: null,
      required: true
    }
  },

  data () {
    return {
      translateLoadingIndex: -1,
      translateLoading: false,
      loading: true,
      error: null,
      text: '',
      activeCommentIndex: null
    }
  },

  computed: {
    ...mapState(['items', 'meta']),

    avatarPlug () {
      return require(`@/assets/img/${this.themePath}/person-plug-mini.png`)
    },

    firstLevelComments () {
      return cloneDeep(this.items.filter(el => !el.repliedId))
    },

    themePath () {
      return getThemePath()
    }
  },

  watch: {
    slug () {
      this.getComments()
    }
  },

  created () {
    this.getComments()
  },

  methods: {
    requiredRule,

    async translateComment (commentIndex) {
      try {
        this.translateLoading = true
        const userLang = localStorage.getItem('public:language')

        const translated = !this.firstLevelComments[commentIndex].isTranslated
          ? await translate(this.firstLevelComments[commentIndex].text, userLang)
          : this.firstLevelComments[commentIndex].text

        this.$set(this.firstLevelComments[commentIndex], 'translated', translated)
        this.firstLevelComments[commentIndex].isTranslated = !this.firstLevelComments[commentIndex].isTranslated
      } finally {
        this.translateLoading = false
      }
    },

    ...mapActions([
      'fetchComments',
      'sendMessage'
    ]),

    toggleComment (index) {
      this.activeCommentIndex = index
    },

    async getComments () {
      try {
        this.loading = true
        await this.fetchComments({
          entity: this.entity,
          slug: this.slug
        })
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    },

    async onSubmitAnswer ({ text, repliedId }, cb) {
      await this.sendMessage(({
        slug: this.slug,
        entity: this.entity,
        comment: { text, repliedId }
      }))
      this.activeCommentIndex = null
      notifySuccess(this.$t('success'))
      cb()
    },

    async onSubmit () {
      if (!this.$refs.form.validate()) return
      try {
        await this.sendMessage({
          entity: this.entity,
          slug: this.slug,
          comment: {
            text: this.text
          }
        })
        this.$refs.form.reset()
        notifySuccess(this.$t('success'))
        this.scrollComment()
      } catch (e) {
        notifyError(this.$t('errors.default'))
      }
    },

    getAnswersByCommentId (id) {
      return this.items.filter(el => el.repliedId === id)
    },

    scrollComment () {
      const element = document.querySelector('.comments-list__item:last-child')
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    },
    getLinkByFileId
  }
}
</script>

<style lang="scss">
/* stylelint-disable max-nesting-depth */
@import "~vuetify/src/styles/styles";

.comments-module {
  .comments {
    &__top {
      position: relative;
      margin-bottom: 40px;
      display: flex;
    }

    &__list {
      max-width: calc(100% - 200px);

      @include below(map_get($grid-breakpoints, sm)) {
        max-width: 100%;
      }
    }

    &__count {
      position: absolute;
      right: 40px;
      top: calc(100% + 30px);

      @include below(map_get($grid-breakpoints, sm)) {
        display: none !important;
      }
    }

    &__mobile-count {
      position: absolute;
      right: 0;
      top: -12px;
    }

    &__input {
      flex: 1;
    }

    &__button {
      width: 220px;
      margin-left: 38px;

      @include below(map_get($grid-breakpoints, sm)) {
        display: none;
      }
    }

    &__submit-btn {
      width: 100%;
    }
  }

  .count {
    display: flex;
    align-items: center;

    &__text {
      margin-bottom: -3px;
      margin-left: 15px;
      opacity: 0.9;
      color: $dark-blue-color;
    }
  }

  .comments-list {
    &__item {
      margin-bottom: 40px;

      @include below(map_get($grid-breakpoints, sm)) {
        margin-bottom: 30px;
      }
    }
  }

  .comment {
    &__main {
      display: flex;
    }

    &__avatar {
      width: 60px !important;
      height: 60px !important;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;

      @include below(map_get($grid-breakpoints, sm)) {
        width: 35px;
        height: 35px;
      }
    }

    &__content {
      padding-top: 5px;
      margin-left: 20px;

      @include below(map_get($grid-breakpoints, sm)) {
        margin-left: 10px;
        padding-top: 7px;
      }
    }

    &__top {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 22px;
      opacity: 0.6;
    }

    &__text {
      opacity: 0.9;

      @include below(map_get($grid-breakpoints, sm)) {
        padding-top: 14px;
        margin-left: -45px;
      }
    }

    &__answers {
      padding-top: 17px;
      padding-left: 80px;

      @include below(map_get($grid-breakpoints, sm)) {
        padding-left: 0;
      }
    }

    &__form {
      padding-left: 80px;

      @include below(map_get($grid-breakpoints, sm)) {
        padding-left: 0;
      }
    }

    &__reply {
      display: flex;
      padding-top: 10px;

      @include below(map_get($grid-breakpoints, sm)) {
        margin-left: -45px;
      }
    }

    &__answer {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .answer {
    display: flex;
    min-height: 40px;
    padding-left: 10px;
    border-left: 1px solid rgba(#252525, 0.2);

    &__avatar {
      width: 40px !important;
      height: 40px !important;
      flex-shrink: 0;
      border-radius: 50%;

      @include below(map_get($grid-breakpoints, sm)) {
        display: none;
      }
    }

    &__text {
      margin-left: 20px;
      padding-top: 8px;
      opacity: 0.9;

      @include below(map_get($grid-breakpoints, sm)) {
        margin-left: 2px;
      }
    }
  }

  .star-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $white-color;

    &__image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%);
      width: calc(140% + 20px);
    }

    &__count {
      position: relative;
    }
  }

  .reply {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.6;

    &__icon {
      transform: scaleY(-1);

      i {
        font-size: 28px;
      }
    }

    &__text {
      margin-left: 4px;
    }
  }

  .v-btn {
    transition-duration: unset;
    transition-property: unset;
    text-transform: unset;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: normal;

    &:before { /* stylelint-disable-line */
      all: unset;
    }
  }

  .translate {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.6;
    margin-left: 35px;

    &:hover {
      opacity: unset;
      filter: invert(44%) sepia(9%) saturate(7349%) hue-rotate(205deg) brightness(83%) contrast(94%);
    }

    &__icon {
      margin-right: 8px;

      img {
        vertical-align: middle;
      }
    }
  }

  .button-more {
    border-radius: 15px !important;
    padding: 0 23px 0 20px !important;
    box-shadow: none !important;
    background-color: rgba(#252525, 0.05) !important;
    text-transform: none !important;

    i {
      margin-left: auto !important;
    }
  }
}
</style>
